import React, { Component } from 'react';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import './App.css';

export default class Tables extends Component {
    state = {
        from: '',
        to: '',
        outlets: [],
        selectedOption: '',
        isLoading: true,
        isSaving: false,
        showError: false,
    }

    componentDidMount() {
        const env = localStorage.getItem('env');
        fetch("https://spades-backend.herokuapp.com/get-outlets", {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({env}),
        }).then((res) => res.json())
        .then(response => {
            if(response.status===401){
                this.setState({showError: true, isLoading: false});
            } else{
            const outletsList = [];
            response.forEach(key => {
                outletsList.push({
                    value: key.name,
                    label: key.name,
                    restauarntBranchId: key.restauarntBranchId,
                });
            })
            this.setState({ outlets: outletsList, isLoading: false })
        }
        }).catch(error => {
            this.setState({showError: true});
        });;
    }

    createTable = async () => {
        const { from, to, selectedOption } = this.state;
        const formData = new FormData();
        formData.append("from", from);
        formData.append("to", to);
        formData.append("outletId", selectedOption.restauarntBranchId);
        formData.append("outletName", selectedOption.value);
        const env = localStorage.getItem('env');
        formData.append("env", env);
        this.setState({ isSaving: true, showError: false });

        fetch("https://spades-backend.herokuapp.com/create-tables", {
            method: 'POST',
            body: formData
        })
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else return response.json();
          })
          .then((data) => {
            this.setState({ isSaving: false, newTables: data });
          })
          .catch((error) => {
            this.setState({ showError: true, isSaving: false });
          });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.createTable();
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleChangeDropdown = (selectedOption) => {
        this.setState({ selectedOption });
    };

    handleMenuUpload = event => {
        this.setState({ outletMenu: event.target.files[0] });
    };

    renderTableUrl = () => {
        return(
            `${this.state.newTables[0].tableUrl}`
        )
    }

    render() {
        const { outlets, isLoading, isSaving, newTables, showError } = this.state;
        if (isLoading) return <div>
            <h1> Pleses wait some time.... </h1> </div> ;
        return (
            <div className="App">
                <form
                    className="App-form"
                    id="main-login"
                    onSubmit={this.onSubmit}>
                    <h4>{showError && 'Error Occured! Please try again'}</h4>
                    <h4>{!isEmpty(newTables) && this.renderTableUrl()}</h4>

                    <h2 className="App-header">Create Tables</h2>
                    <label className="App-label-wrapper">
                    <span className="App-label">Outlet</span>
                    <Select 
                        options={outlets} 
                        onChange={this.handleChangeDropdown}
                    />
                    </label>
                    <br/>
                    <label>
                        <span className="App-label">From Table No</span>
                        <input className="App-input" type="text" value={this.state.from} name="from" onChange={this.handleChange} />
                    </label>
                    <br />
                    <label>
                        <span className="App-label">To Table No</span>
                        <input className="App-input" type="text" value={this.state.to} name="to" onChange={this.handleChange} />
                    </label>
                    <br />
                    {isSaving ? <p>Loading</p> : 
                        <input className="App-button" type="submit" value="Submit" />
                    }
                </form>
            </div>
        );
    }
}