export const userService = {
    login,
    logout,
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`https://spades-backend.herokuapp.com/users/authenticate`, requestOptions)
        .then(res => res.json())
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                user.authdata = window.btoa(user.username + ':' + user.password);
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        })
        .catch(err => {
            return err;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}