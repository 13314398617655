import React, { Component } from 'react';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import getApiUrl from './config';
import './App.css';

export default class Locations extends Component {
    state = {
        outlets: [],
        selectedOption: '',
        isLoading: true,
        showError: false,
    }

    componentDidMount() {
        const env = localStorage.getItem('env');
        fetch("https://spades-backend.herokuapp.com/get-outlets", {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ env }),
        }).then((res) => res.json())
            .then(response => {
                if(response.status===401){
                    this.setState({showError: true, isLoading: false});
                } else{
                const outletsList = [];
                response.forEach(key => {
                    outletsList.push({
                        value: key.name,
                        label: key.name,
                        restauarntBranchId: key.restauarntBranchId,
                        name: key.name,
                        branchCode: key.branchCode,
                    });
                })
                this.setState({ outlets: outletsList, isLoading: false })
            }
            }).catch(error => {
                this.setState({showError: true});
            });;
    }

    renderOutlets = () => {
        const { outlets } = this.state;
        const env = localStorage.getItem('env');
        const baseUrl = getApiUrl(env);

        return (
            outlets.map((outlet, i) => {
                const editTableUrl = `${baseUrl}/editTableNumber/${outlet.branchCode}`
                return (
                    <tr className="App-list-col" key={i}>
                        <td>{outlet.name}</td>
                        <td>{editTableUrl}</td>
                    </tr>
                )
            })
        )
    }

    render() {
        const { outlets, isLoading, showError } = this.state;
        if (isLoading) return <div>
            <h1> Pleses wait some time.... </h1> </div>;
        return (
            <div className="App">
                <div className="App-form">
                    <h4>{showError && 'Error Occured! Please try again'}</h4>
                    <h2 className="App-header">Locations</h2>
                    <table className="App-list">
                        <thead>
                            <tr>
                                <th className="App-list-col">Outlet</th>
                                <th className="App-list-col">Landing URL</th>
                            </tr>
                        </thead>
                        <tbody className="App-list-row">
                            {!isEmpty(outlets) && this.renderOutlets()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}