module.exports = function getApiUrl(env) {
    let url = 'https://app.dev.spadesapp.io';
    switch(env) {
        case 'production':
            url = 'https://app.spadesapp.io';
            break;
        case 'development':
            url = 'https://app.dev.spadesapp.io';
            break;
        case 'sandbox':
            url = 'https://app.sandbox.spadesapp.io';
            break;
        case 'demo':
            url = 'https://app.demo.spadesapp.io';
            break;
        default:
            url = 'https://app.dev.spadesapp.io';
      }
      return url;
}