import React, { Component } from 'react';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import './App.css';

export default class Outlet extends Component {
    state = {
        outletName: '',
        address: '',
        telephone: '',
        openingHours: '',
        brands: [],
        selectedOption: '',
        isLoading: true,
        outletMenu: {},
        isSaving: false,
        showError: false,
        newOutlet: {},
    }

    componentDidMount() {
        const env = localStorage.getItem('env');
        fetch("https://spades-backend.herokuapp.com/get-brands", {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({env})
        }).then((res) => res.json())
        .then(response => {
            if(response.status===401){
                this.setState({showError: true, isLoading: false});
            } else{
            const brandsList = [];
            response.forEach(key => {
                brandsList.push({
                    value: key.name,
                    label: key.name,
                    restauarntChainId: key.restauarntChainId
                });
            })
            this.setState({ brands: brandsList, isLoading: false })
        }
        }).catch(error => {
            this.setState({showError: true});
        });
    }

    createOutlet = async () => {
        const { outletName, address, telephone, openingHours, selectedOption, outletMenu } = this.state;
        const formData = new FormData();

        for (let i = 0; i < outletMenu.length; i++) {
            formData.append(`outletMenu`, outletMenu[i])
        }
        // formData.append("outletMenu", outletMenu);
        formData.append("outletName", outletName);
        formData.append("address", address);
        formData.append("telephone", telephone);
        formData.append("openingHours", openingHours);
        formData.append("restauarntChainId", selectedOption.restauarntChainId);

        const env = localStorage.getItem('env');
        formData.append("env", env);
        this.setState({ isSaving: true, showError: false });

        fetch("https://spades-backend.herokuapp.com/create-outlet", {
            method: 'POST',
            body: formData
        })
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else return response.json();
          })
          .then((data) => {
            this.setState({ isSaving: false, newOutlet: data.data });
          })
          .catch((error) => {
            this.setState({ showError: true, isSaving: false });
          });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.createOutlet();
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleChangeDropdown = (selectedOption) => {
        this.setState({ selectedOption });
    };

    handleMenuUpload = event => {
        const files = event.target.files;
        this.setState({ outletMenu: event.target.files });
    };

    render() {
        const { brands, isLoading, isSaving, newOutlet, showError } = this.state;
        if (isLoading) return <div>
            <h1> Pleses wait some time.... </h1> </div> ;
        return (
            <div className="App">
                <form
                    className="App-form"
                    id="main-login"
                    onSubmit={this.onSubmit}>
                    <h4>{showError && 'Error Occured! Please try again'}</h4>
                    <h4>{!isEmpty(newOutlet) && 'Outlet is created successfully'}</h4>

                    <h2 className="App-header">Create Outlet</h2>
                    <label className="App-label-wrapper">
                    <span className="App-label">Brand</span>
                    <Select 
                        options={brands} 
                        onChange={this.handleChangeDropdown}
                    />
                    </label>
                    <br/>
                    <label>
                        <span className="App-label">Name</span>
                        <input className="App-input" type="text" value={this.state.outletName} name="outletName" onChange={this.handleChange} />
                    </label>
                    <br />
                    <label>
                        <span className="App-label">Address</span>
                        <input className="App-input" type="text" value={this.state.address} name="address" onChange={this.handleChange} />
                    </label>
                    <br />
                    <label>
                        <span className="App-label">Telephone</span>
                        <input className="App-input" type="text" value={this.state.telephone} name="telephone" onChange={this.handleChange} />
                    </label>
                    <br />
                    <label>
                        <span className="App-label">Opening Hours</span>
                        <input className="App-input" type="text" value={this.state.openingHours} name="openingHours" onChange={this.handleChange} />
                    </label>
                    <br />
                    <label>
                        <span className="App-label">Outlet Menu</span>
                        <input className="App-input" type="file" name="outletMenu" onChange={this.handleMenuUpload} multiple/>
                    </label>
                    {isSaving ? <p>Loading</p> : 
                        <input className="App-button" type="submit" value="Submit" />
                    }
                </form>
            </div>
        );
    }
}