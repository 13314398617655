import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import './App.css';
export default class Group extends Component {
    state = {
        groupName: '',
        groupLogo: {},
        isLoading: false,
        showError: false,
        newGroup: {},
    }

    createGroup = async () => {
        const { groupName, groupLogo } = this.state;
        const formData = new FormData();
        const env = localStorage.getItem('env');

        formData.append("file", groupLogo);
        formData.append("name", groupLogo.name);
        formData.append("groupName", groupName);
        formData.append("env", env);

        this.setState({ isLoading: true, showError: false });

        fetch("https://spades-backend.herokuapp.com/create-group", {
            method: 'POST',
            body: formData
        })
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then((data) => {
                if (data.status === 401) {
                    this.setState({ showError: true, isLoading: false });
                } else {
                    this.setState({ isLoading: false, newGroup: data.data });
                }
            })
            .catch((error) => {
                this.setState({ showError: true, isLoading: false });
            });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.createGroup();
    }

    handleFileUpload = event => {
        this.setState({ groupLogo: event.target.files[0] });
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        const { isLoading, showError, newGroup } = this.state;

        return (
            <div className="App">
                <form
                    className="App-form"
                    id="main-login"
                    onSubmit={this.onSubmit}>
                    <h4>{showError && 'Error Occured! Please try again'}</h4>
                    <h4>{!isEmpty(newGroup) && 'Group is created successfully'}</h4>
                    <h2 className="App-header">Create Group</h2>
                    <label>
                        <span className="App-label">Name</span>
                        <input className="App-input" type="text" value={this.state.groupName} name="groupName" onChange={this.handleChange} />
                    </label>
                    <br />
                    <label>
                        <span className="App-label">Group Logo</span>
                        <input className="App-input" type="file" name="groupLogo" onChange={this.handleFileUpload} />
                    </label>
                    {isLoading ? <p>Loading</p> :
                        <label>
                            <input className="App-button" type="submit" value="Submit" />
                        </label>}
                </form>
            </div>
        );
    }
}