import Group from './group';
import Brand from './brand';
import Outlet from './outlet';
import Tables from './tables';
import Orders from './createOrder';
import Locations from './locations';
import LoginPage from './LoginPage';
import { PrivateRoute } from './PrivateRoute';
import { Router, Route, Link, Redirect } from 'react-router-dom';
import { Component } from 'react';
import history from './history';
import NavBar from './NavBar';
export default class Pages extends Component {

    state = {
        user: {},
        selectedOption: '',
        env: 'development',
    }

    componentDidMount() {
        this.setState({
            user: JSON.parse(localStorage.getItem('user')),
        });
    }

    handleChangeEnv = (event) => {
        this.setState({ env: event.target.value });
        localStorage.setItem('env', event.target.value);
    }

    render() {
        const { env } = this.state;

        return (
            <div className="main">
                <Router
                    forceRefresh={true}
                    history={history}>
                    {localStorage.getItem('user') && <NavBar handleChangeEnv={this.handleChangeEnv}/>}
                    <div className="content">
                        <PrivateRoute exact path="/"  component={() => (<Group env={env} />)} />
                        <PrivateRoute path="/brand" component={() => (<Brand env={env} />)} />
                        <PrivateRoute path="/outlet" component={() => (<Outlet env={env} />)} />
                        <PrivateRoute path="/tables" component={() => (<Tables env={env} />)} />
                        <PrivateRoute path="/orders" component={() => (<Orders env={env} />)} />
                        <PrivateRoute path="/locations" component={() => (<Locations env={env} />)} />
                        <Route path="/login" component={LoginPage} />
                    </div>
                </Router>
            </div>
        )
    }
}