import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { userService } from './user.service';

import './App.css';

export default class NavBar extends Component {

    state = {
        user: {},
    }

    render() {
        const { user, env } = this.state;
        const { handleChangeEnv } = this.props;

        return (
            <div className="menu">
                <ul className="menu-list">
                    <li className="menu-list-item"> <Link className="menu-list-link" to="/">Groups</Link> </li>
                    <li className="menu-list-item"> <Link className="menu-list-link" to="/brand">Brands</Link> </li>
                    <li className="menu-list-item"> <Link className="menu-list-link" to="/outlet">Outlets</Link> </li>
                    <li className="menu-list-item"> <Link className="menu-list-link" to="/tables">Tables</Link> </li>
                    <li className="menu-list-item"> <Link className="menu-list-link" to="/orders">Create Orders</Link> </li>
                    <li className="menu-list-item"> <Link className="menu-list-link" to="/locations">Locations</Link> </li>
                    <li className="menu-list-item">
                        <select value={env} onChange={handleChangeEnv}>
                            <option value="development">Development</option>
                            <option value="sandbox">Sandbox</option>
                            <option value="demo">Demo</option>
                            <option value="production">Production</option>
                        </select>
                    </li>
                </ul>
                {localStorage.getItem('user') && <button onClick={userService.logout}>Logout</button>}
            </div>
        )
    }
}