import React, { Component } from 'react';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import './App.css';

export default class Brand extends Component {
    state = {
        brandName: '',
        facebook: '',
        twitter: '',
        instagram: '',
        groups: [],
        selectedOption: '',
        isLoading: true,
        brandMenu: {},
        brandLogo: {},
        isSaving: false,
        showError: false,
        newBrand: {},
    }

    componentDidMount() {
        const env = localStorage.getItem('env');
        fetch("https://spades-backend.herokuapp.com/get-groups", {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({env}),
        }).then((res) => res.json())
        .then(response => {
            console.log("response", response);
            if(response.status===401){
                this.setState({showError: true, isLoading: false});
            } else{
            const groupsList = [];
            response.forEach(key => {
                groupsList.push({
                    value: key.name,
                    label: key.name,
                    merchantId: key.merchantId
                });
            })
            this.setState({ groups: groupsList, isLoading: false })
        }
        })
        .catch(error => {
            this.setState({showError: true});
        });
    }

    createBrand = async () => {
        const { brandName, facebook, twitter, instagram, selectedOption, brandLogo, brandMenu } = this.state;
        const formData = new FormData();

        formData.append("brandLogo", brandLogo);
        formData.append("brandMenu", brandMenu);
        formData.append("brandName", brandName);
        formData.append("facebook", facebook);
        formData.append("twitter", twitter);
        formData.append("instagram", instagram);
        formData.append("merchantId", selectedOption.merchantId);
        const env = localStorage.getItem('env');
        formData.append("env", env);
        this.setState({ isSaving: true, showError: false });

        fetch("https://spades-backend.herokuapp.com/create-brand", {
            method: 'POST',
            body: formData
        })
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else return response.json();
          })
          .then((data) => {
            this.setState({ isSaving: false, newBrand: data.data });
          })
          .catch((error) => {
            this.setState({ showError: true, isSaving: false });
          });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.createBrand();
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleChangeDropdown = (selectedOption) => {
        this.setState({ selectedOption });
    };

    handleLogoUpload = event => {
        this.setState({ brandLogo: event.target.files[0] });
    };

    handleMenuUpload = event => {
        this.setState({ brandMenu: event.target.files[0] });
    };

    render() {
        const { groups, isLoading, isSaving, newBrand, showError } = this.state;
        if (isLoading) return <div>
            <h1> Pleses wait some time.... </h1> </div> ;
   
        return (
            <div className="App">
                <form
                    className="App-form"
                    id="main-login"
                    onSubmit={this.onSubmit}>
                    <h4>{showError && 'Error Occured! Please try again'}</h4>
                    <h4>{!isEmpty(newBrand) && 'Brand is created successfully'}</h4>

                    <h2 className="App-header">Create Brand</h2>
                    <label className="App-label-wrapper">
                        <span className="App-label">Group</span>
                        <Select 
                        options={groups} 
                        onChange={this.handleChangeDropdown}
                    />                    
                    </label>
                    <br />
                    <label>
                        <span className="App-label">Name</span>
                        <input className="App-input" type="text" value={this.state.brandName} name="brandName" onChange={this.handleChange} />
                    </label>
                    <br />
                    <label>
                        <span className="App-label">Facebook</span>
                        <input className="App-input" type="text" value={this.state.facebook} name="facebook" onChange={this.handleChange} />
                    </label>
                    <br />
                    <label>
                        <span className="App-label">Twitter</span>
                        <input className="App-input" type="text" value={this.state.twitter} name="twitter" onChange={this.handleChange} />
                    </label>
                    <br />
                    <label>
                        <span className="App-label">Instagram</span>
                        <input className="App-input" type="text" value={this.state.instagram} name="instagram" onChange={this.handleChange} />
                    </label>
                    <br />
                    <label>
                        <span className="App-label">Brand Logo</span>
                        <input className="App-input" type="file" name="brandLogo" onChange={this.handleLogoUpload} />
                    </label>
                    <br/>
                    <label>
                        <span className="App-label">Brand Menu</span>
                        <input className="App-input" type="file" name="brandMenu" onChange={this.handleMenuUpload} />
                    </label>
                    <br />
                    {isSaving ? <p>Loading</p> : 
                    <label>
                        <input className="App-button" type="submit" value="Submit" />
                    </label>}                
                </form>
            </div>
        );
    }
}